import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
// import LogoAnimation from "../Animations/LogoAnimation";
// import Login from "../login/Login";
import { getAuth, getGuuid, getGuuidToken, getDecryptMsisdn, getNet, getDeNet, getJtToken } from "../../ApiCalls/Auth/api_v1_auth";
import { useEffect, useState } from "react";
import Loader from '../Animations/Loader';
const useAuth = () => {
    const cookies = new Cookies();
    let isTokenAvailable = localStorage.getItem("token");
    let isUuid = cookies.get('uid');
    let utype = cookies.get('u');
    let rmsid = cookies.get('rmsid');
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);
    let location = useLocation();
    const [loggedIn, setLoggedIn] = useState(null);
    const { REACT_APP_NDNC_HOST_NAME, REACT_APP_LOGIN_URL, REACT_APP_LOGIN_URL_NDNC } = process.env;
    const hostname = window.location.hostname;
    const src = new URLSearchParams(window.location.search).get("source");
    let ndnc = 0;
    if (hostname == REACT_APP_NDNC_HOST_NAME || (hostname != REACT_APP_NDNC_HOST_NAME && src == 1)) {
        ndnc = 1;
    }
    useEffect(() => {

        // if (isUuid === null) {
        //     window.location.ref = "http://127.0.0.1:8000/0auth?uuid=1";
        // }
        cookies.set('uid_exp', 0, { path: '/', expires: nextYear });
        // if (isTokenAvailable) {
        //     // if (isUuid == undefined) {
        //     //     cookies.set('uid_exp', 1, { path: '/', expires: nextYear });
        //     // }
        //     const decoded = jwt_decode(isTokenAvailable);
        //     const msisdn = decoded.userInfo['msisdn'];
        //     const auth = decoded.userInfo['auth'];

        //     if (auth === 1 && msisdn.toString().length === 12) {
        //         // Both auth is 1 and msisdn length is 12
        //         setLoggedIn(true);
        //     } else if (msisdn.toString().length === 12 && (auth === 0 || auth === 1)) {
        //         // Either msisdn length is 12 and auth is 0 or msisdn length is 12 and auth is 1
        //         setLoggedIn(true);
        //     } else {
        //         setLoggedIn(true);
        //     }
        // } else
        const fetchData = async () => {
            let auth;
            let msisdn;
            try {
                const decoded = jwt_decode(isTokenAvailable);
                let u = decoded.userInfo['msisdn'];
                const authenticateUser = await getDecryptMsisdn({ encMsisdn: u });
                // console.log(authenticateUser);
                if ('access_token' in authenticateUser.headers) {
                    const accessToken = authenticateUser.headers['access_token'];
                    if (accessToken !== null && accessToken !== undefined) {
                        localStorage.setItem('token', accessToken);
                    }
                }
                auth = authenticateUser.data.data.suv;
                msisdn = authenticateUser.data.data.msisdn;
            } catch {
                auth = 0;
                msisdn = 0;
            }
            if (auth === 1 && msisdn.toString().length === 12) {
                // Both auth is 1 and msisdn length is 12
                setLoggedIn(true);
            }
            else {
                const getToken = async () => await getJtToken({ "msisdn": rmsid }).then(authenticateUser => {
                    if (authenticateUser.status == 200 && authenticateUser.data.message === "ok") {
                        if ('access_token' in authenticateUser.headers) {
                            const accessToken = authenticateUser.headers['access_token'];
                            if (accessToken !== null && accessToken !== undefined) {
                                localStorage.setItem('token', accessToken);
                            }
                        }
                        setLoggedIn(true);
                    }
                    else {
                        setLoggedIn(false);
                    }
                }).catch(error => {
                    setLoggedIn(false);
                })
                getToken();
                // setLoggedIn(false);
            }
        };
        if (isTokenAvailable) {
            fetchData();
        } else
        // if (isTokenAvailable == null && isUuid == null) 
        {
            console.log(1);
            // const redirect = new URLSearchParams(window.location.search).get("r");
            // if (redirect === null) {
            //     const queryParameters = window.location.search; // Get all query parameters
            //     const redirectURL = process.env.REACT_APP_SESSION_URL + queryParameters + (queryParameters ? "&uuid=-1&s=" + ndnc : "?uuid=-1&s=" + ndnc);
            //     window.location.href = redirectURL;
            //     return;
            // }
            cookies.set('clickid', new URLSearchParams(window.location.search).get("clickid"), { path: '/', expires: nextYear });
            cookies.set('cpid', new URLSearchParams(window.location.search).get("cpid"), { path: '/', expires: nextYear });
            cookies.set('base', new URLSearchParams(window.location.search).get("base"), { path: '/', expires: nextYear });
            cookies.set('uid', new URLSearchParams(window.location.search).get("user"), { path: '/', expires: nextYear });
            cookies.set('u', new URLSearchParams(window.location.search).get("uuid"), { path: '/', expires: nextYear });
            cookies.set('source', new URLSearchParams(window.location.search).get("source"), { path: '/', expires: nextYear });
            cookies.set('tc', new URLSearchParams(window.location.search).get("tc"), { path: '/', expires: nextYear });
            // -1 = wifi else mobile
            let guuidData = {
                "user": new URLSearchParams(window.location.search).get("user"),
                "h": new URLSearchParams(window.location.search).get("user"),
                "tc": parseInt(new URLSearchParams(window.location.search).get("tc")) || 101,
                "cpid": parseInt(new URLSearchParams(window.location.search).get("cpid")) || 0,
                "clickid": new URLSearchParams(window.location.search).get("clickid") ? new URLSearchParams(window.location.search).get("clickid") : 0,
                "uid": cookies.get('uid')
            }
            getGuuid(guuidData).then(function (res) {
                const msg = res.data.message;
                const stts = res.data.data.status;

                if (msg === "ok") {
                    const uid = res.data.data.uid;
                    const utype = res.data.data.u;
                    const rmsid = res.data.data.rmsid;

                    cookies.set('uid', uid, { path: '/', expires: nextYear });
                    cookies.set('u', utype, { path: '/', expires: nextYear });
                    cookies.set('rmsid', rmsid, { path: '/', expires: nextYear });

                    const accessToken = res.headers['access_token'];
                    localStorage.setItem('token', accessToken);

                    if (stts === 'subscriber') {
                        // login user
                        cookies.set('uid_exp', 0, { path: '/', expires: nextYear });
                        setLoggedIn(true);
                    } else if (stts === 'guest') {
                        // guest
                        cookies.remove('uid_exp');
                        setLoggedIn(true);
                    } else if (stts === 'expired') {
                        // expired
                        cookies.set('uid_exp', 1, { path: '/', expires: nextYear });
                        setLoggedIn(false);
                    }
                }
            });


        }
        // else if (isUuid) {
        //     let tokenData = {
        //         uid: isUuid,
        //         type: utype,
        //         rmsid: rmsid,
        //     }
        //     const fetchData = async () => await getGuuidToken(tokenData).then(response => {
        //         if (response.status == 200 && response.data.status === "ok") {
        //             let accessToken = response.data.token;
        //             localStorage.setItem('token', accessToken);
        //             setLoggedIn(true);
        //         }
        //         else {
        //             setLoggedIn(false);
        //         }
        //     }).catch(error => {
        //         setLoggedIn(false);
        //     })
        //     fetchData();
        // }
        // else {
        //     let isToken = localStorage.getItem("token");
        //     let tokenData = {
        //         token: isToken
        //     }
        //     const fetchData = async () => await getAuth(tokenData).then(response => {
        //         if (response.status == 200 && response.data.status === "ok" || response.data.status === "refresh") {
        //             if (response.data.status === "refresh") {
        //                 let accessToken = response.data.token;
        //                 localStorage.setItem('token', accessToken);
        //             }
        //             setLoggedIn(true);
        //         }
        //         else {
        //             // console.log("ss",loggedIn)
        //             setLoggedIn(false);
        //         }
        //     }).catch(error => {
        //         // localStorage.removeItem('token');
        //         setLoggedIn(false);
        //     })
        //     fetchData();
        // }

    }, [location.pathname])
    return loggedIn
}


const ProtectedRoutes = () => {
    let location = useLocation();
    const isAuth = useAuth();
    if (isAuth === null) // waiting..
    { return <Loader />; }
    // <LogoAnimation />;

    // if(isAuth &&  location.pathname==="/" || location.pathname==="/login")
    // {
    //     // console.log(location.pathname);    
    //      return <Navigate to="/home" />
    // }
    if (location.pathname === "/share/watch") {
        // console.log(location.pathname);    
        return <Outlet />
    }
    return isAuth ? <Outlet /> : <Navigate to="/login" />
    // <Navigate to="/" />
    // <Login />
}


export default React.memo(ProtectedRoutes);