import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AvatarBuilder, { AvatarProperties } from '../avatar/components/AvatarProperties';
import { getUser } from "../../ApiCalls/Profile/api_v1_profile";

import editIcon from '../../images/v1/profile/basil_edit-outline.svg';
import analyticsIcon from '../../images/v1/profile/hugeicons_analytics-01.svg';
import themesIcon from '../../images/v1/profile/fluent_dark-theme-24-regular.svg';
import musicLibraryIcon from '../../images/v1/profile/codicon_music.svg';
import settingsIcon from '../../images/v1/profile/ep_setting.svg';
import changeBitmojiIcon from '../../images/v1/profile/solar_camera-linear.svg';
import hourglassIcon from '../../images/v1/rewards/hourglass.svg';
import chevNext from '../../images/v1/profile/chev_next.svg';
import { loadAvatar } from '../../ApiCalls/Profile/api_v1_profile';

const ProfileNew = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: '',
    phoneNumber: '',
    avatarUrl: '',
  });
  const [userOptions, setUserOptions] = useState(AvatarProperties.defaultProperties);

  useEffect(() => {
    const loadSavedOptions = async () => {
      try {
        const response = await loadAvatar();
        if (response && response.data && response.data.avatar) {
          const avatarData = response.data.avatar;
          const relevantOptions = {
            style: avatarData.style || "Transparent",
            topType: avatarData.topType || "Flat",
            accessoriesType: avatarData.accessoriesType || "Nothing",
            hairColor: avatarData.hairColor || "Black",
            facialHairType: avatarData.facialHairType || "Nothing",
            facialHairColor: avatarData.facialHairColor || "BlondeGolden",
            clotheType: avatarData.clotheType || "Crew Neck Tee",
            clotheColor: avatarData.clotheColor || "Blue01",
            eyeType: avatarData.eyeType || "Default",
            eyebrowType: avatarData.eyebrowType || "Default",
            mouthType: avatarData.mouthType || "Default",
            skinColor: avatarData.skinColor || "White"
          };
          console.log('Relevant avatar options:', relevantOptions);
          setUserOptions(relevantOptions);
        }
      } catch (error) {
        console.error('Error loading saved avatar options:', error);
      }
    };
    loadSavedOptions();
  }, []);

  useEffect(() => {
    document.title = "Profile | Bingebit";
  }, [])

  useEffect(() => {
    getUser().then(response => {
      const accessToken = response.headers['access_token'];
      if (accessToken != null) {
        localStorage.setItem('token', accessToken);
      }

      let data = response.data.data;
      let name = data.name;
      let avatarUrl = data.avatar_url;
      let avatarName = data.avatar_name;
      let fullPhone = data.msisdn;
      let formattedPhone = formatPhoneNumber(fullPhone);

      setUserData({
        name: name || formattedPhone,
        phoneNumber: formattedPhone,
        avatarUrl: avatarName !== "user" ? avatarUrl : null,
      });

      if (avatarName !== "user") {
        localStorage.setItem("avatar", avatarUrl);
      }
    }).catch(error => {
      console.error('Error fetching user data:', error);
    });
  }, []);

  const formatPhoneNumber = (phone) => {
    if (phone && phone.length >= 7) {
      let firstPart = phone.slice(2, 5);
      let secondPart = phone.slice(5);
      return "+92 " + firstPart + " " + secondPart;
    }
    return phone;
  };

  const styles = `
    .profile-container {
      color: white;
      padding: 20px;
      width: 100%;
      max-width: 360px;
      margin: 0 auto;
    }

    .profile-header {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    .avatar-container2 {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: beige;
      margin-right: 15px;
      flex-shrink: 0;
      overflow: hidden;
      position: relative;
    }

    .avatar-wrapper2 {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform-origin: center;
      overflow: hidden;
    }

    .avatar-wrapper2 > div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar-wrapper2 svg {
      width: 120%;
      height: 120%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .profile-info {
      flex-grow: 1;
    }

    .username {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .phone-number {
      font-size: 14px;
      color: #CCCCCC;
      margin-bottom: 10px;
    }

    .change-bitmoji {
      display: inline-flex;
      align-items: center;
      background-color: #FFDD00;
      padding: 8px 12px;
      border-radius: 20px;
      cursor: pointer;
      font-size: 14px;
      color: #252525;
    }

    .change-bitmoji img {
      margin-right: 8px;
      width: 20px;
    }

    .bitmoji-timer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      padding: 8px 12px;
      border-radius: 10px;
      background-color: #2C2C2C;
      font-size: 14px;
    }

    .bitmoji-timer img {
      width: 20px;
      margin-right: 10px;
    }

    .menu {
      margin-top: 20px;
    }

    .menu-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      background-color: #333333;
      border-radius: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .menu-item img {
      width: 24px;
      margin-right: 15px;
    }

    .menu-item span {
      flex-grow: 1;
      font-size: 16px;
    }

    .menu-item-icon {
      width: 16px;
    }
  `;






  return (
    <>
      <style>{styles}</style>
      <div className="profile-container">
        <div className="profile-header">
          <div className="avatar-container2">
            <div className="avatar-wrapper2">
              <AvatarBuilder properties={userOptions} />
            </div>
          </div>
          <div className="profile-info">
            <div className="username">{userData.name}</div>
            <div className="phone-number">{userData.phoneNumber}</div>
            <div className="change-bitmoji" onClick={() => navigate('/avatar-editor')}>
              <img src={changeBitmojiIcon} alt="Change Bitmoji" />
              <span>Change my Avatar</span>
            </div>
          </div>
        </div>

        {/* <div className="bitmoji-timer">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={hourglassIcon} alt="Hourglass" />
                        <span>Bitmoji can be changed after XX hrs</span>
                    </div>
                    <a href="/" style={{ color: '#FFDD00' }}>More Info</a>
                </div> */}

        <div className="menu">
          <div className="menu-item" onClick={() => navigate('/edit-profile')}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={editIcon} alt="Edit Profile" />
              <span>Edit Profile</span>
            </div>
            <img src={chevNext} className="menu-item-icon" alt="Go" />
          </div>

          <div className="menu-item" onClick={() => navigate('/analytics')}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={analyticsIcon} alt="Analytics" />
              <span>Analytics</span>
            </div>
            <img src={chevNext} className="menu-item-icon" alt="Go" />
          </div>

          {/* <div className="menu-item">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={themesIcon} alt="Custom Themes" />
                            <span>Custom Themes</span>
                        </div>
                        <img src={chevNext} className="menu-item-icon" alt="Go" />
                    </div> */}

          {/* <div className="menu-item">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={musicLibraryIcon} alt="Music Library" />
                            <span>Music Library</span>
                        </div>
                        <img src={chevNext} alt="Go" />
                    </div> */}
          {/* 
                    <div className="menu-item" onClick={() => navigate('/profile-settings')}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={settingsIcon} alt="Settings" />
                            <span>Settings</span>
                        </div>
                        <img src={chevNext} alt="Go" />
                    </div> */}
        </div>
      </div>
      <br /> <br /> <br />
    </>
  );
};

export default ProfileNew;