import axiosClient from "../axiosClient";

export function getAuth(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/authenticate', JSON.stringify(data));
}

export async function getNet() {
    // let res = await axiosClient.get('https://www.dot-jo.biz/appgw/GetPartnerHEMSISDN?partnerId=technowiz-5940f450');
    // let data = res.data;
    // return data;
    return axiosClient.get('http://www.dot-jo.biz/appgw/GetPartnerHEMSISDN?partnerId=technowiz-5940f450').then(response => {
        let encMsisdn = response.data.encMSISDN;
        let opid = response.data.opId;
        let errCode = response.data.errorCode;
        let heId = response.data.heId;
        if (errCode == 0 && opid == 21) {
            let telco_code = 101;
            let res = { "encMsisdn": encMsisdn, "errCode": errCode, "telco_code": telco_code, "heId": heId };
            let retrn = JSON.stringify(res);

            return retrn;
            // headToLanding(encMsisdn,telco_code,heId)     
        }
        else {
            let res = { "errCode": errCode };
            let retrn = JSON.stringify(res);
            return retrn;

        }
    }).catch(error => {
        let res = { "errCode": error };
        let retrn = JSON.stringify(res);
        return retrn;
    })

}

export async function getDeNet(data) {
    // console.log(data);
    return axiosClient.post('http://161.97.158.142:8094/wap/he/dt/decrypt/msisdn/tw-16', JSON.stringify(data)).then(response => {
        let encMsisdn = response.data.msisdn;
        let hashedMsisdn = response.data.msisdnRefKey;
        let errCode = response.data.resultCode;

        if (errCode == 0) {
            let res = { "errCode": 0, "encMsisdn": encMsisdn, "hashedMsisdn": hashedMsisdn };
            let retrn = JSON.stringify(res);
            return retrn;
            // headToLanding(encMsisdn,telco_code,heId)     
        }
        else {
            let res = { "errCode": -1, "encMsisdn": -1, "hashedMsisdn": -1 };
            let retrn = JSON.stringify(res);
            return retrn;

        }
    }).catch(error => {
        let res = { "errCode": -1, "encMsisdn": -1, "hashedMsisdn": -1 };
        let retrn = JSON.stringify(res);
        return retrn;
    })
}
export function getDecryptMsisdn(data) {
    return axiosClient.post('/dt/decrypt/msisdn', JSON.stringify(data))

}
export function getGuuid(data) {
    return axiosClient.post('/guuid', JSON.stringify(data));
}

export function getGuuidToken(data) {
    return axiosClient.post('/guuid/token', JSON.stringify(data));
}
export function getJtToken(data) {
    return axiosClient.post('/jt/gen', JSON.stringify(data));
}


export function getEncMsiUnsub(data) {
    return axiosClient.post('https://unsubscribe.jazz.ding.pk/api/encrypt', JSON.stringify(data));
}
