import React, { useRef, useEffect, useState } from 'react';
import styles from '../../styles/header/header.module.css';
import ding_token from '../../images/v1/home/ding_token.png';
import star_token from '../../images/v1/home/token.png';
import { coinsTimeout } from "../../ApiCalls/Header/api_v1_header";
import AvatarBuilder, { AvatarProperties } from '../avatar/components/AvatarProperties';
import { getUserCoins, getStreaksInfo } from '../../ApiCalls/Rewards/api_v1_rewards';
import { loadAvatar } from '../../ApiCalls/Profile/api_v1_profile';
import { useNavigate } from 'react-router-dom';
import coinsIcon from '../../images/v1/rewards/streak_icon.png'

function Header({ isPlaying, isBuffering }) {
    const [seconds, setSeconds] = useState(0);
    const [showAccumulation, setShowAccumulation] = useState(false);
    const [coinBalance, setCoinBalance] = useState(0);
    const [dailyStreak, setDailyStreak] = useState(0);
    const coinsMaker = useRef(0);
    const intervalRef = useRef(null);
    const counterRunning = useRef(false);
    const [userOptions, setUserOptions] = useState(AvatarProperties.defaultProperties);
    const navigate = useNavigate();

    useEffect(() => {
        const loadSavedOptions = async () => {
            try {
                const response = await loadAvatar();
                if (response && response.data && response.data.avatar) {
                    const avatarData = response.data.avatar;
                    const relevantOptions = {
                        style: avatarData.style || "Transparent",
                        topType: avatarData.topType || "Flat",
                        accessoriesType: avatarData.accessoriesType || "Nothing",
                        hairColor: avatarData.hairColor || "Black",
                        facialHairType: avatarData.facialHairType || "Nothing",
                        facialHairColor: avatarData.facialHairColor || "BlondeGolden",
                        clotheType: avatarData.clotheType || "Crew Neck Tee",
                        clotheColor: avatarData.clotheColor || "Blue01",
                        eyeType: avatarData.eyeType || "Default",
                        eyebrowType: avatarData.eyebrowType || "Default",
                        mouthType: avatarData.mouthType || "Default",
                        skinColor: avatarData.skinColor || "White"
                    };
                    setUserOptions(relevantOptions);
                }
            } catch (error) {
                console.error('Error loading saved avatar options:', error);
            }
        };
        loadSavedOptions();
    }, []);

    useEffect(() => {
        fetchCoinBalance();
        fetchDailyStreak();
        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        if (seconds === 999) {
            stopCounter();
            setSeconds(0);
        }
        if (coinsMaker.current === 10 || coinsMaker.current === 999) {
            coinsMaker.current = 0;
            coinsTimeout({ "t": 10 });
            fetchCoinBalance();
            fetchDailyStreak();
        }
    }, [seconds]);

    useEffect(() => {
        if (isPlaying && isBuffering === false && counterRunning.current === false) {
            counterRunning.current = true;
            startCounter();
        } else {
            counterRunning.current = false;
            stopCounter();
        }
    }, [isPlaying, isBuffering]);

    const startCounter = () => {
        if (intervalRef.current !== null) return;
        intervalRef.current = setInterval(() => {
            setSeconds(prev => prev + 1);
            coinsMaker.current++;
        }, 1000);
    };

    const stopCounter = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
    };

    const showCoinsAcumulation = () => {
        setShowAccumulation((prevState) => !prevState);
    };

    const fetchCoinBalance = async () => {
        try {
            const response = await getUserCoins();
            setCoinBalance(response.data.data.coins);
        } catch (error) {
            console.error('Failed to fetch coin balance:', error);
        }
    };

    const fetchDailyStreak = async () => {
        try {
            const response = await getStreaksInfo();
            setDailyStreak(response.data.data.daily_streak);
        } catch (error) {
            console.error('Failed to fetch daily streak:', error);
        }
    };

    return (
        <>
            <div className={`${styles.header} d-flex flex-row position-fixed justify-content-between z1`} style={{ left: "0", right: "0" }} onContextMenu={(e) => e.preventDefault()}>
                <div className={`${styles.borderBottom} ${styles.ml} ${styles.colorWhite}`}>
                    <a href="#" className={styles.logoText}>bingebit</a>
                </div>
                <div className={`${styles.ml} ${styles.colorWhite} ${styles.headerRight} d-flex align-items-center`}>
                    <div className={`${styles.coinWrapper} d-flex align-items-center mr-3`}>
                        <img src={ding_token} alt="Bingebit token" className={styles.coinIcon} />
                        <span className={styles.coinCount}>{coinBalance}</span>
                    </div>
                    <div className={`${styles.flameWrapper} d-flex align-items-center mr-3`}>
                        {/* <span className={styles.flameEmoji} role="img" aria-label="flame">🔥</span> */}
                        <img className={styles.flameEmoji} src={coinsIcon} />
                        <span className={styles.flameCount}>{dailyStreak}</span>
                    </div>
                    <div className={styles.avatarWrapper} onClick={() => navigate('/profile')}>
                        <div className={styles.avatarContainer}>
                            <AvatarBuilder properties={userOptions} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.ml} ${styles.colorWhite} ${styles.timerWrapper}`} onClick={showCoinsAcumulation}>
                {/* <a href="#" className={styles.headerLinks}>Get Rewards</a>{">"} */}
                <img className={styles.starToken} src={star_token} alt="" width="30" height="30" />
                <div className={styles.moveableTimer}>
                    <div id={styles.secondsCounter}> {seconds}</div>
                    <img className={styles.dingToken} src={ding_token} alt="" />
                </div>
            </div>
            {showAccumulation && (
                <div className={styles.accumulationInfo}>
                    <h2 style={{ fontSize: "20px", textAlign: "center" }}>
                        Every second <br />
                        you spend <br />
                        to watch video, <br />
                        you earn one GOLD COIN
                    </h2>
                    <div className={styles.closeAccumulation} onClick={showCoinsAcumulation}>
                        <p>X</p>
                    </div>
                </div>
            )
            }
        </>
    );
}

export default React.memo(Header);