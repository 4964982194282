import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { editUser, saveUser } from "../../ApiCalls/Profile/api_v1_profile";
import jwt_decode from "jwt-decode";

const EditProfile = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    birthday: '',
    gender: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const birthdayRef = useRef(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    document.title = "Edit Profile | Bingebit";
  }, [])

  const fetchUserData = async () => {
    try {
      const response = await editUser();
      const data = response.data.data;
      setUserData({
        name: data.name || '',
        email: data.email || '',
        birthday: data.birthday || '',
        gender: data.gender || ''
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = {
        name: userData.name,
        email: userData.email,
        birthday: userData.birthday,
        gender: userData.gender
      };

      const response = await saveUser(formData);
      if (response.status === 200) {
        navigate("/profile");
      }
    } catch (error) {
      console.error('Error saving user data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const styles = `
    .edit-profile-container {
      background-color: #191919;
      color: white;
      padding: 20px;
      max-width: 400px;
      margin: 0 auto;
    }

    .input-group {
      margin-bottom: 20px;
    }

    .input-group label {
      display: block;
      margin-bottom: 5px;
    }

    .input-group input,
    .input-group select,
    .input-group input:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
    .input-group select:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
      width: 100% !important;
      padding: 10px !important;
      border-radius: 8px !important;
      border: 1px solid #FFDD00 !important;
      background-color: #333333 !important;
      color: white !important;
      margin-left: 0 !important;
    }

    .save-button {
      width: 100%;
      padding: 10px;
      border-radius: 20px !important;
      background-color: #FFDD00;
      color: #191919;
      border: none;
      font-weight: bold;
      cursor: pointer;
    }

    .save-button:disabled {
      background-color: #666666;
      cursor: not-allowed;
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="edit-profile-container">
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={userData.name}
              onChange={handleInputChange}
              ref={nameRef}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
              ref={emailRef}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="birthday">Birthday</label>
            <input
              type="date"
              id="birthday"
              name="birthday"
              value={userData.birthday}
              onChange={handleInputChange}
              ref={birthdayRef}
            />
          </div>

          <div className="input-group">
            <label htmlFor="gender">Gender</label>
            <select
              id="gender"
              name="gender"
              value={userData.gender}
              onChange={handleInputChange}
            >
              <option value="">Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Prefer not to say</option>
            </select>
          </div>

          <button type="submit" className="save-button" disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save Profile'}
          </button>
        </form>
      </div>
    </>
  );
};

export default EditProfile;